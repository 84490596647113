import { Route, Routes } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import Login from './pages/Login';
import Insight from './pages/Insight';
import InsightDetails from './pages/InsightDetails';
import Connect from './pages/Connect';
import ErrorPage from './pages/ErrorPage';
import './App.css';
import ProtectedRoute from './utils/ProtectedRoute';
import { AdminDashboard } from './pages/AdminDashboard';
import { AdminUsers } from './pages/AdminUsers/Index';
import { AdminProjects } from './pages/AdminProjects';
import { LayoutProvider } from './contexts/LayoutContext';
import { Account } from './pages/Account';
import { Layout } from './components/Layout';
import { PersistLogin } from './components/PersistLogin';
import { ROLES } from './types/contract';

function App() {
  return (
    <LayoutProvider>
      <Routes>
        <Route
          path="/"
          element={<Layout />}
        >
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/connect"
            element={<Connect />}
          />
          <Route element={<PersistLogin />}>
            <Route element={<ProtectedRoute allowedRoles={[ROLES.USER, ROLES.ADMIN]} />}>
              <Route
                path="/"
                element={<Insight />}
              />
              <Route
                path="/insight"
                element={<Insight />}
              />
              <Route
                path="/connect"
                element={<Connect />}
              />
              <Route
                path="/account"
                element={<Account />}
              />
              <Route
                path="/insight-details/:leadId"
                element={<InsightDetails />}
              />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[ROLES.ADMIN]} />}>
              <Route path="admin">
                <Route
                  index
                  element={<AdminDashboard />}
                />
                <Route
                  path="users"
                  element={<AdminUsers />}
                />
                <Route
                  path="projects"
                  element={<AdminProjects />}
                />
              </Route>
            </Route>
          </Route>

          <Route
            path="*"
            element={<ErrorPage />}
          />
        </Route>
      </Routes>
    </LayoutProvider>
  );
}

export default App;
