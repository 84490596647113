import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { IProject, IProjectsState, IReqestProject, IUpdatePayload } from '../../types/contract';
import axiosIns from '../../utils/axiosInstance';
import { RootState } from '../store';

const initialState: IProjectsState = {
  isLoading: false,
  projects: [],
  error: null,
};

export const fetchProjects = createAsyncThunk<
  IProject[],
  undefined,
  { state: RootState }
>('projects', async () => {
  return await axiosIns.get('projects/');
});

export const createProject = createAsyncThunk<
  IProject,
  IReqestProject,
  { state: RootState }
>('projects/create', async (projectPayload) => {
  return await axiosIns.post('projects/', projectPayload);
});

export const updateProject = createAsyncThunk<IProject, IUpdatePayload>(
  'projects/update',
  async ({ id, payload }) => {
    return await axiosIns.put(`projects/${id}`, payload);
  },
);

export const removeProject = createAsyncThunk<IProject, number>(
  '/projects/delete',
  async (id) => {
    return await axiosIns.delete(`projects/${id}`);
  },
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers(builder: ActionReducerMapBuilder<IProjectsState>) {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
      })
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state, {payload: createdProject}) => {
        state.isLoading = false;
        state.projects.push(createdProject);
        state.error = null;
      })
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateProject.fulfilled,
        (state, {payload: updatedProject}) => {
          state.isLoading = false;
          state.projects = state.projects.map((p) =>
            p.id === updatedProject.id ? updatedProject : p,
          );
        },
      )
      .addCase(removeProject.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(
        removeProject.fulfilled,
        (state, {payload: removedProject}) => {
          state.isLoading = false;
          state.projects = state.projects.filter(
            (p) => p.id !== removedProject.id,
          );
        },
      );
  },
});

export const selectProjects = (state: RootState) => state.projects;

export default projectsSlice.reducer;
