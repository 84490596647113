import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { API_ENDPOINT } from '../../utils/apis';
import axiosIns from '../../utils/axiosInstance';
import { RootState } from '../store';

export const fetchLeadById: any = createAsyncThunk(
  'fetch/leadById',
  async (leadId) => {
    return await axiosIns.get(API_ENDPOINT.GET_LEAD + `/${leadId}`);
  },
);

interface ILeadState {
  isLoading: boolean;
  leadDetail: any;
  error: { message?: string } | any;
}

const initialState: ILeadState = {
  isLoading: false,
  leadDetail: null,
  error: null,
};

const leadDetailSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {},
  extraReducers(builder: ActionReducerMapBuilder<ILeadState>) {
    builder
      .addCase(fetchLeadById.pending, (state: ILeadState) => {
        state.isLoading = true;
      })
      .addCase(fetchLeadById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leadDetail = action.payload;
      })
      .addCase(fetchLeadById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

export const selectLeadDetail = (state: RootState) => state.leadDetail;

export default leadDetailSlice.reducer;
