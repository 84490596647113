import { useState, useEffect, FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import Header from '../../components/Header';
import InsightDetailsHeader from '../../components/InsightComponents/InsightDetailsHeader';
import Table from '../../components/Table';
import { COLUMNS_CUSTOMER_DETAILS } from '../../constants/columns_data';
import mock_data from '../../constants/mock_data_customer_info.json';
import CreditRiskTab from '../../components/InsightComponents/CreditRiskTab';
import OverviewTab from '../../components/InsightComponents/OverviewTab';
import IncomeTab from '../../components/InsightComponents/IncomeTab';
import UserAnalysisTab from '../../components/InsightComponents/UserAnalysisTab';
import {
  fetchLeadById,
  selectLeadDetail,
} from '../../app/slices/leadDetailSlice';
import './index.css';

Chart.register(CategoryScale);
const tabs = [
  'Overview',
  // 'Income',
  // 'Credit Risk',
  // 'User Analysis',
  // 'History',
];

interface InsightDetailsProps {
  children?: ReactNode;
}

const InsightDetails: FC<InsightDetailsProps> = () => {
  const { leadId } = useParams();
  const dispatch = useDispatch();
  const { leadDetail, isLoading } = useSelector(selectLeadDetail);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (leadId && parseInt(leadId) > 0) {
      dispatch(fetchLeadById(leadId));
    }
  }, [leadId, dispatch]);

  const breadcumbs = [
    { label: leadDetail?.firstName + ' ' + leadDetail?.lastName },
  ];

  return (
    <>
      <Header isLoading={isLoading} breadcumbs={breadcumbs} />
      <div>
        <InsightDetailsHeader
          tabs={tabs}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
        {activeTab === tabs[0] && (
          <OverviewTab
            lead={leadDetail}
            accounts={leadDetail?.__accounts__ || []}
            isLoading={isLoading}
          />
        )}
        {activeTab === tabs[1] && <IncomeTab />}
        {activeTab === tabs[2] && <CreditRiskTab />}
        {activeTab === tabs[3] && <UserAnalysisTab />}

        {activeTab === tabs[4] && (
          <div className="insightDetails__content">
            <div className="insightDetails__contentRight">
              <div className="insightDetails__table">
                <Table
                  isLoading={isLoading}
                  isNested={true}
                  tableDatas={mock_data}
                  tableColumns={COLUMNS_CUSTOMER_DETAILS}
                  tab={{ activeTab: 'All', page: 'insight_detail' }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InsightDetails;
