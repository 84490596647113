import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import '../../pages/Login/index.css';
import './index.css';
import Prelaoder from '../../pages/Preloader';
import { projectFormResolver } from './schema';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { createProject, selectProjects, updateProject } from '../../app/slices/projectsSlice';
import { IProject, IReqestProject } from '../../types/contract';

export interface ProjectFormProps {
  project: IProject | undefined;
  isOpen?: boolean | undefined;
  setIsOpen: (arg: boolean) => void;
}

export const ProjectForm: FC<ProjectFormProps> = ({ project, isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectProjects);

  const defaultValues = {
    name: '',
    site: '',
    description: '',
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IReqestProject>({
    defaultValues,
    mode: 'onChange',
    resolver: projectFormResolver,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen]);

  useEffect(() => {
    if (project !== undefined) {
      reset({
        name: project.name,
        site: project?.site,
        description: project?.description,
      });
    } else {
      reset(defaultValues);
    }
  }, [project, reset]);

  const onSubmit = async (data: IReqestProject) => {
    try {
      if (project) {
        await dispatch(
          updateProject({
            id: project.id,
            payload: {
              name: data.name,
              site: data?.site || '',
              description: data?.description || '',
            },
          }),
        );
      } else {
        await dispatch(createProject(data));
      }
      reset();
      setIsOpen(false);
    } catch (error) {
      console.log('catch error', error);
    }
  };

  return (
    <div className="user-form">
      <h3 className="user-form-title">{project ? 'Update' : 'Create'} Project</h3>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <label htmlFor="name">Project Name</label>
          <input
            id="name"
            {...register('name')}
            placeholder="Project name"
            className={`${!!errors.name && 'error-input'}`}
          />
          <span className="error-msg">{errors?.name?.message}</span>
        </div>
        <div className="form-row">
          <label htmlFor="site">Site</label>
          <input
            id="site"
            {...register('site')}
            placeholder=""
            className={`${!!errors.site && 'error-input'}`}
          />
          <span className="error-msg">{errors?.site?.message}</span>
        </div>
        <div className="form-row">
          <label htmlFor="description">Description</label>
          <input
            id="description"
            type="text"
            {...register('description')}
            className={`${!!errors.description && 'error-input'}`}
          />
          <span className="error-msg">{errors?.description?.message}</span>
        </div>
        <button
          className="submit-btn"
          type="submit"
          disabled={isLoading}
        >
          {project ? 'Update' : 'Create'} <Prelaoder loader={isLoading} />
        </button>
      </form>
    </div>
  );
};
