import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';

interface LayoutContextInterface {
  expand: boolean;
  isAdmin: boolean;
  setExpand: (arg: boolean) => void;
  setIsAdmin: (arg: boolean) => void;
}

export const LayoutContext =
  createContext<LayoutContextInterface | null>(null);

interface LayoutProviderProps {
  children: ReactElement;
}

export const LayoutProvider: FC<LayoutProviderProps> = ({
  children,
}) => {
  const [expand, setExpand] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width && width < 1024) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  }, [width]);

  return (
    <LayoutContext.Provider
      value={{ expand, isAdmin, setExpand, setIsAdmin }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

/**
 * Hook to get layout state
 */
export const useLayout = () => {
  return useContext(LayoutContext) as LayoutContextInterface;
};
