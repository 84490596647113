import React from 'react';
import { Pie } from 'react-chartjs-2';
import mock_data_government from '../../../constants/mock_data_government_income.json';
import Table from '../../Table';
import { COLUMNS_INCOME_GOVERNMENT } from '../../../constants/columns_data';
import { GOVERMNET_INCOME_DATA } from '../../../constants/chart_data';
import InsightDetailsInfo from '../InsightDetailsInfo';
import './index.css';

const IncomeTab: React.FC = () => {
  return (
    <div className="insightDetails__content">
      <div className="insightDetails__contentRight">
        <InsightDetailsInfo />
        <h3>
          Government Income Breakdown (Last 90 Days)
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17_136)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_136">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </h3>
        <div className="insightDetails__contentChartContainer">
          <div className="insightDetails__table">
            <Table
              isNested={true}
              tableDatas={mock_data_government}
              expand={false}
              tableColumns={COLUMNS_INCOME_GOVERNMENT}
            />
          </div>
          <div className="insightDetails__contentChart">
            <Pie data={GOVERMNET_INCOME_DATA} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeTab;
