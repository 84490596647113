import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns/esm';
import { FC, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from 'react-super-responsive-table';
import {
  removeProject,
  selectProjects,
} from '../../app/slices/projectsSlice';
import { useAppDispatch, useAppSelector } from '../../app/store';
import Header from '../../components/Header';
import { ProjectForm } from '../../components/ProjectForm';
import { SideSlide } from '../../components/SideSlide';
import { useLayout } from '../../contexts/LayoutContext';
import { IProject } from '../../types/contract';
import '../AdminDashboard/index.css';
import './index.css';

export interface AdminProjectsProps {}

export const AdminProjects: FC<AdminProjectsProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setIsAdmin } = useLayout();

  const { isLoading, projects } = useAppSelector(selectProjects);
  const [isOpen, setIsOpen] = useState(false);
  const [project, setProject] = useState<IProject | undefined>(
    undefined,
  );

  useEffect(() => {
    setIsAdmin(true);
  }, [navigate, setIsAdmin, dispatch]);

  const handleCreateProject = () => {
    setIsOpen(true);
    setProject(undefined);
  };
  const handleUpdateProject = (id: number) => {
    setIsOpen(true);
    setProject(projects.find((p) => p.id === id));
  };

  const handleConfirmDelete = (id: number) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => submitDelete(id),
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
      overlayClassName: 'confirm-delete-alert',
    });
  };

  const submitDelete = async (id: number) => {
    try {
      await dispatch(removeProject(id));
    } catch (error) {}
  };

  return (
    <>
      <Header breadcumbs={[]} />
      <div className="admin-container">
        <div className="new-user">
          <button
            className="submit-button"
            onClick={handleCreateProject}
          >
            New Project
          </button>
        </div>
        <div className="insight__table project__table">
          <Table>
            <Thead>
              <Tr>
                <Th>Project Name</Th>
                <Th>Site</Th>
                <Th>Description</Th>
                <Th>Signed Date</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                projects.map((p) => (
                  <Tr key={p.id}>
                    <Td>{p.name}</Td>
                    <Td>{p.site}</Td>
                    <Td>{p.description}</Td>
                    <Td>
                      {format(new Date(p.createdAt), 'yyyy-MM-dd')}
                    </Td>
                    <Td>
                      <div className="admin-user__actions-group">
                        <span
                          className="edit"
                          onClick={() => handleUpdateProject(p.id)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </span>
                        <span
                          className="remove"
                          onClick={() => handleConfirmDelete(p.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </div>
                    </Td>
                  </Tr>
                ))}
              {isLoading &&
                Array(3)
                  .fill(1)
                  .map((v, i) => (
                    <Tr key={i}>
                      <Td>
                        <Skeleton width={80} height={18} />
                      </Td>
                      <Td>
                        <Skeleton width={80} height={18} />
                      </Td>
                      <Td>
                        <Skeleton width={80} height={18} />
                      </Td>
                      <Td>
                        <Skeleton width={80} height={18} />
                      </Td>
                      <Td>
                        <Skeleton width={80} height={18} />
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </div>
      </div>
      <SideSlide open={isOpen} onClose={() => setIsOpen(false)}>
        <ProjectForm
          isOpen={isOpen}
          project={project}
          setIsOpen={setIsOpen}
        />
      </SideSlide>
    </>
  );
};
