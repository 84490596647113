import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
  PayloadAction,
} from '@reduxjs/toolkit';
import { ILead } from '../../types/lead';
import { API_ENDPOINT } from '../../utils/apis';
import axiosIns from '../../utils/axiosInstance';
import { RootState } from '../store';

export const fetchLeads: any = createAsyncThunk(
  'fetch/leads',
  async () => {
    return await axiosIns.get(API_ENDPOINT.GET_LEAD);
  },
);

export const fetchLeadsByProject: any = createAsyncThunk(
  'fetch/leads?project=:projectId',
  async (projectId: string) => {
    return await axiosIns.get(
      API_ENDPOINT.GET_LEAD + '?projectId=' + projectId,
    );
  },
);

interface ILeadsState {
  isLoading: boolean;
  leads: ILead[];
  filtered: ILead[];
  error: { message?: string } | null;
}

const initialState: ILeadsState = {
  isLoading: false,
  leads: [],
  filtered: [],
  error: null,
};

function checkLead(lead: ILead) {
  return true;
}

const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    addLead: (state, action): any => {
      state.leads.push(action.payload);
    },
    filterLeads: (state, { payload }: PayloadAction<string>) => {
      if (payload) {
        state.filtered = state.leads.filter((lead) => {
          const key = payload.toUpperCase();
          return (
            (lead.OwnerName || '').toUpperCase().includes(key) 
            // || (lead.email || '').includes(key) 
            // || lead.phoneNumber?.includes(key)
          );
        });
      } else {
        state.filtered = state.leads;
      }
    },
  },
  extraReducers(builder: ActionReducerMapBuilder<ILeadsState>) {
    builder
      .addCase(fetchLeads.pending, (state: ILeadsState) => {
        state.isLoading = true;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.leads = action.payload;
        state.filtered = state.leads;
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLeadsByProject.pending, (state: ILeadsState) => {
        state.isLoading = true;
      })
      .addCase(
        fetchLeadsByProject.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.isLoading = false;
          state.leads = action.payload;
          state.filtered = state.leads;
        },
      )
      .addCase(fetchLeadsByProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const selectLeads = (state: RootState) => state.leads;
export const { addLead, filterLeads } = leadsSlice.actions;
export default leadsSlice.reducer;
