import { ReactNode } from 'react';
import Header from '../../components/Header';
import './index.css';

interface ConnectProps {
  children?: ReactNode;
}
const Connect: React.FC<ConnectProps> = () => {


  return (
    <>
      <Header breadcumbs={[]} />
      {/* <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/4rLW7zg21gI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <br />
      <br />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1672542.6833284285!2d-107.44562996115563!3d35.039611270328244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1671498712143!5m2!1sen!2s"
        width={600}
        height={450}
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}

      <br />
      <br />
      <div className="iframe-wrapper">
        <iframe
          src="http://sophtron-widget.s3-website-us-east-1.amazonaws.com/"
          width={350}
          height={600}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Connect;
