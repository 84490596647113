import mock_data_government from './mock_data_government_income.json';
import mock_data_loan from './mock_data_loan.json';
import mock_data_bill from './mock_data_bill.json';
import mock_data_government_breakdown from './mock_data_government.json';

export const GOVERMNET_INCOME_DATA = {
  labels: mock_data_government.map((data) => data.income_source),
  datasets: [
    {
      label: '# of Votes',
      data: mock_data_government.map((data) =>
        Number(data.amount.substring(1)),
      ),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const BREAKDOWN_LOAN_BILL_DATA = {
  labels: ['Loan Breakdown', 'Bill Breakdown'],
  datasets: [
    {
      label: '# of Votes',
      data: [
        mock_data_loan
          .map((data) => Number(data.amount.substring(1)))
          .reduce((a, b) => a + b, 0),
        mock_data_bill
          .map((data) => Number(data.amount.substring(1)))
          .reduce((a, b) => a + b, 0),
      ],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const BREAKDOWN_USER_ANALYSIS_DATA_LOAN = {
  labels: mock_data_loan.map((data) => data.loan_source),
  datasets: [
    {
      label: '# of Votes',
      data: mock_data_loan.map((data) =>
        Number(data.amount.substring(1)),
      ),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
export const BREAKDOWN_USER_ANALYSIS_DATA_BILL = {
  labels: mock_data_bill.map((data) => data.loan_source),
  datasets: [
    {
      label: '# of Votes',
      data: mock_data_bill.map((data) =>
        Number(data.amount.substring(1)),
      ),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
export const BREAKDOWN_USER_ANALYSIS_DATA_GOVERNMENT = {
  labels: mock_data_government_breakdown.map(
    (data) => data.loan_source,
  ),
  datasets: [
    {
      label: '# of Votes',
      data: mock_data_government_breakdown.map((data) =>
        Number(data.amount.substring(1)),
      ),
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};
