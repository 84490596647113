import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import './index.css';

const InsightHeader: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState('Day');

  return (
    <div className="insight__header">
      <ul className="insight__headerOptions">
        <li>Customers</li>
      </ul>
      {/* <div className="insight__headerRight">
        <span className="insight__headerTotalText">
          Total
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17_136)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_136">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <div className="insight__headerRightVolume">
          <span>
            26 <br /> <strong>Volumes</strong>
          </span>
          <span>
            31 <br /> <strong>Users</strong>
          </span>
          <span>
            - <br /> <strong>Refreshes</strong>
          </span>
          <select
            name="date"
            id=""
            value={selectedDate}
            onChange={(e: any) => setSelectedDate(e.target.value)}
          >
            <option value="Day"> Day</option>
            <option value="Month">Month</option>
            <option value="Year">Year</option>
          </select>
        </div>

        <button className="insight__headerBtnRefresh">
          Update
          <FontAwesomeIcon icon={faArrowsRotate} />
        </button>
      </div> */}
    </div>
  );
};

export default InsightHeader;
