import { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Select from 'react-select';
import '../../pages/Login/index.css';
import './index.css';
import Prelaoder from '../../pages/Preloader';
import { signupResolver, updateResolver } from './schema';
import { createOfficer, selectOfficers, updateOfficer } from '../../app/slices/officersSlice';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { selectProjects } from '../../app/slices/projectsSlice';
import { IOfficer, ISignupPayload, ROLES } from '../../types/contract';
import { toast } from 'react-toastify';

interface IReqestUser {
  username: string;
  email: string;
  password?: string;
  confirm_password?: string;
  projects: { label: string; value: string }[];
  role: { label: string; value: string };
}
interface UserFormProps {
  user?: IOfficer;
  isOpen?: boolean;
  setIsOpen?: (arg: boolean) => void;
  cantEditProject?: boolean;
}

export const UserForm: FC<UserFormProps> = ({ user, isOpen, setIsOpen, cantEditProject }) => {
  const dispatch = useAppDispatch();
  const { projects } = useAppSelector(selectProjects);
  const { isLoading, error } = useAppSelector(selectOfficers);
  const options = projects.map((p) => ({
    label: p.name,
    value: p.id.toString(),
  }));

  const defaultValues = {
    username: '',
    email: '',
    password: '',
    projects: [options[0]],
    role: { label: 'user', value: 'user' },
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm<IReqestUser>({
    defaultValues,
    mode: 'onChange',
    resolver: user ? updateResolver : signupResolver,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [isOpen]);

  useEffect(() => {
    if (user !== undefined) {
      reset({
        username: user.username,
        email: user.email,
        password: '',
        confirm_password: '',
        projects: user.projects.map((p) => ({
          label: p.name,
          value: p.id.toString(),
        })),
        role: { label: user.role, value: user.role },
      });
    } else {
      reset(defaultValues);
    }
  }, [user]);

  const onSubmit = async (data: IReqestUser) => {
    try {
      if (user) {
        await dispatch(
          updateOfficer({
            id: user.id,
            payload: {
              username: data.username,
              email: data.email,
              projects: data.projects.map((p) => p.value),
              role: data.role.value,
            },
          }),
        ).then((data: any) => {
          if (!data?.error) {
            reset();
            setIsOpen && setIsOpen(false);
            toast.success('User was updated!');
          } else {
            toast.error(data.error.message || 'Bad request: duplicated entry was found');
          }
        });
      } else {
        await dispatch(
          createOfficer({
            ...data,
            projects: data.projects.map((p) => p.value),
            role: data.role.value,
          } as ISignupPayload),
        ).then((data: any) => {
          if (!data?.error) {
            reset();
            setIsOpen && setIsOpen(false);
            toast.success('New user was created!');
          } else {
            toast.error(data.error.message || 'Bad request: duplicated entry was found');
          }
        });
      }
    } catch (error) {
      console.log('catch error', error);
    }
  };

  return (
    <div className="user-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            {...register('username')}
            placeholder="Username"
            className={`${!!errors.username && 'error-input'}`}
          />
          <span className="error-msg">{errors?.username?.message}</span>
        </div>
        <div className="form-row">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            {...register('email')}
            placeholder="test@gmail.com"
            className={`${!!errors.email && 'error-input'}`}
          />
          <span className="error-msg">{errors?.email?.message}</span>
        </div>
        <div className="form-row">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            {...register('password')}
            className={`${!!errors.password && 'error-input'}`}
          />
          <span className="error-msg">{errors?.password?.message}</span>
        </div>
        {!user && (
          <>
            <div className="form-row">
              <label htmlFor="confirm_password">Confirm Password</label>
              <input
                id="confirm_password"
                type="password"
                {...register('confirm_password')}
                className={`${!!errors.confirm_password && 'error-input'}`}
              />
              <span className="error-msg">{errors?.confirm_password?.message}</span>
            </div>
          </>
        )}
        <div className="form-row">
          <label htmlFor="password">Projects</label>
          <Controller
            name="projects"
            control={control}
            render={({ field }) => (
              <Select
                isDisabled={cantEditProject}
                className={`${errors.projects ? 'error' : 'select-project'}`}
                {...field}
                options={options}
                placeholder={'Select projects...'}
                isMulti
                defaultValue={options[0]}
              />
            )}
          />
          <span className="error-msg">{errors?.projects?.message}</span>
        </div>
        <div className="form-row">
          <label htmlFor="password">User roles</label>
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={Object.values(ROLES).map((role) => ({ label: role, value: role }))}
                placeholder={'Select roles...'}
              />
            )}
          />
          <span className="error-msg">{errors?.projects?.message}</span>
        </div>
        <button
          className="submit-btn"
          type="submit"
          disabled={isLoading}
        >
          Submit <Prelaoder loader={isLoading} />
        </button>
      </form>
    </div>
  );
};
