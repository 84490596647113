import {
  FC,
  Fragment,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import Header from '../../components/Header';
import InsightHeader from '../../components/InsightComponents/InsightHeader';
import Table from '../../components/Table';
import { LEAD_COLUMNS } from '../../constants/columns_data';
import {
  fetchLeads,
  fetchLeadsByProject,
  filterLeads,
  selectLeads,
} from '../../app/slices/leadsSlice';
import './index.css';
import {
  selectProjects,
} from '../../app/slices/projectsSlice';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { useLayout } from '../../contexts/LayoutContext';
import { ILead } from '../../types/lead';

interface InsightProps {
  children?: ReactNode;
}

const Insight: FC<InsightProps> = () => {
  const dispatch = useAppDispatch();
  const { setIsAdmin } = useLayout();
  const { filtered, isLoading } = useAppSelector(selectLeads);
  const { projects } = useAppSelector(selectProjects);

  const [activeTab] = useState('tabs[0]');
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    setIsAdmin(false);
    dispatch(fetchLeads());
  }, [dispatch, setIsAdmin]);

  useEffect(() => {
    dispatch(filterLeads(keyword));
  }, [keyword]);

  const handleChangeProject = (projectId: string) => {
    if (projectId) {
      dispatch(fetchLeadsByProject(projectId));
    } else {
      dispatch(fetchLeads());
    }
  };

  return (
    <Fragment>
      <Header breadcumbs={[]} />
      <div className="insight container">
        <div className="insight__top">
          <InsightHeader />
          <div className="insight__subHeader">
            <div className="insight__subHeaderOptions">
              <select
                defaultValue={0}
                name="project"
                id="select-project"
                onChange={(e) => handleChangeProject(e.target.value)}
              >
                <option
                  value="0"
                  selected
                >
                  All
                </option>
                {projects.length &&
                  projects.map((p, i) => (
                    <option
                      key={i}
                      value={p.id}
                    >
                      {p.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="insight__subHeaderSearch">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_17_136)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_136">
                    <rect
                      width="15"
                      height="15"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>

              <form>
                <input
                  type="text"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder="Search..."
                />
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </form>
            </div>
          </div>
        </div>
        <div className="insight__table">
          <Table
            isLoading={isLoading}
            tableDatas={filtered.map((l: ILead) => ({
              ...l,
              email: l.email ? l.email[0] : '',
              phoneNumber: l.phoneNumber ? l.phoneNumber[0] : '',
            }))}
            tableColumns={LEAD_COLUMNS}
            tab={{ activeTab, page: 'insight' }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Insight;
