import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './index.css';

interface IInsightDetailsData {
  isLoading?: boolean;
  name: string;
  email: string;
  address: string;
  // financialInstitution: string;
  // employerName: string;
  // loginId: string;
  // requestId: string;
  // requestDateTime: string;
  // requestStatus: number;
}
const status = ['Completed', 'In-Progress', 'Error', 'All'];

const InsightDetailsData: React.FC<IInsightDetailsData> = ({
  isLoading,
  name,
  email,
  address,
  // financialInstitution,
  // employerName,
  // loginId,
  // requestId,
  // requestDateTime,
  // requestStatus,
}) => {
  return (
    <div className="insightDetailsData">
      <h3>Customer Information</h3>
      <ul>
        <li>
          <strong>Name</strong>
          {isLoading ? <Skeleton height={18} width={120} /> : name}
        </li>
        <li>
          <strong>Email</strong>
          {isLoading ? <Skeleton height={18} width={120} /> : email}
        </li>
        <li>
          <strong>Address</strong>
          {isLoading ? <Skeleton height={18} width={120} /> : address}
        </li>
        {/* <li>
          <strong>Financial Institution</strong>
          {financialInstitution}
        </li> */}
        {/* <li>
          <strong>Employer Name</strong>
          {employerName}
        </li>

        <li>
          <strong>Login Id</strong>
          {loginId}
        </li> */}
      </ul>
      {/* <h3>Request Information</h3>
      <ul>
        <li>
          <strong>Request Id</strong>
          {requestId}
        </li>
        <li>
          <strong>Email</strong>
          {email}
        </li>
        <li>
          <strong>Request Date/Time</strong>
          {requestDateTime}
        </li>
        <li>
          <strong>Request Status</strong>
          {status[requestStatus]}
        </li>
        <li>
          <strong>Days Detected</strong>
          Ach Ach Ewc Growth
        </li>
      </ul> */}
    </div>
  );
};

export default InsightDetailsData;
