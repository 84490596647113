import { IOfficer } from '../types/contract';

class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    return user?.accessToken;
  }

  updateLocalAccessToken(token: string) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    localStorage.setItem(
      'user',
      JSON.stringify({ ...user, accesstoken: token }),
    );
  }

  getUser(): IOfficer {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  setUser(user: IOfficer, accessToken: string) {
    localStorage.setItem(
      'user',
      JSON.stringify({ ...user, accessToken }),
    );
  }

  removeUser() {
    localStorage.removeItem('user');
  }
}

export default new TokenService();
