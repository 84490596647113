import { FC, useEffect, useState } from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns/esm';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Header from '../../components/Header';
import { SideSlide } from '../../components/SideSlide';
import { UserForm } from '../../components/UserForm';
import '../AdminDashboard/index.css';
import './index.css';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { fetchOfficers, removeOfficer, selectOfficers } from '../../app/slices/officersSlice';
import { useLayout } from '../../contexts/LayoutContext';
import { IOfficer } from '../../types/contract';

export interface AdminUsersProps {}

export const AdminUsers: FC<AdminUsersProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, officers } = useAppSelector(selectOfficers);
  const { setIsAdmin } = useLayout();

  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState<IOfficer | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchOfficers());
    setIsAdmin(true);
  }, [navigate, setIsAdmin, dispatch]);

  useEffect(() => {
    if (typeof isOpen !== 'undefined' && !isOpen) {
      setUser(undefined);
    }
  }, [isOpen]);

  const handleCreateUser = () => {
    setIsOpen(true);
    setUser(undefined);
  };
  const handleUpdateUser = (id: number) => {
    setIsOpen(true);
    setUser(officers.find((u) => u.id === id));
  };

  const handleConfirmDelete = (id: number) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => submitDelete(id),
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
      overlayClassName: 'confirm-delete-alert',
    });
  };

  const submitDelete = async (id: number) => {
    try {
      await dispatch(removeOfficer(id));
    } catch (error) {}
  };

  return (
    <>
      <Header breadcumbs={[]} />
      <div className="admin-container">
        <div className="new-user">
          <button
            className="submit-button"
            onClick={handleCreateUser}
          >
            Create New User
          </button>
        </div>
        <div className="insight__table">
          <Table>
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Email</Th>
                <Th>Projects</Th>
                <Th>Role</Th>
                <Th>Signed Date</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading &&
                officers.map((user) => (
                  <Tr key={user.id}>
                    <Td>{user.username}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.projects.map((p) => p.name).join(', ')}</Td>
                    <Td>{user.role}</Td>
                    <Td>{format(new Date(user.createdAt), 'yyyy-MM-dd')}</Td>
                    <Td>
                      <div className="admin-user__actions-group">
                        <span
                          className="edit"
                          onClick={() => handleUpdateUser(user.id)}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </span>
                        <span
                          className="remove"
                          onClick={() => handleConfirmDelete(user.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </span>
                      </div>
                    </Td>
                  </Tr>
                ))}
              {isLoading &&
                Array(3)
                  .fill(1)
                  .map((v, i) => (
                    <Tr key={i}>
                      <Td>
                        <Skeleton
                          width={80}
                          height={18}
                        />
                      </Td>
                      <Td>
                        <Skeleton
                          width={80}
                          height={18}
                        />
                      </Td>
                      <Td>
                        <Skeleton
                          width={80}
                          height={18}
                        />
                      </Td>
                      <Td>
                        <Skeleton
                          width={80}
                          height={18}
                        />
                      </Td>
                      <Td>
                        <Skeleton
                          width={80}
                          height={18}
                        />
                      </Td>
                    </Tr>
                  ))}
            </Tbody>
          </Table>
        </div>
      </div>
      <SideSlide
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <>
          <h3 className="user-form-title">{user ? 'Update' : 'Create'} User</h3>
          <br />
          <UserForm
            isOpen={isOpen}
            user={user}
            setIsOpen={setIsOpen}
          />
        </>
      </SideSlide>
    </>
  );
};
