export interface WidgetError {
  error: string | { message?: string; code?: string };
}

export interface IProject {
  id: number;
  name: string;
  site?: string;
  description?: string;
  createdAt: Date;
}

export interface IProjectsState {
  isLoading: boolean;
  projects: IProject[];
  error: { message?: string } | null;
}

export interface projectPayload {
  name: string;
  site?: string;
  description?: string;
}

export interface IUpdatePayload {
  id: number;
  payload: projectPayload;
}

export interface IReqestProject {
  name: string;
  site?: string;
  description?: string;
}

export interface IOfficer {
  id: number;
  username: string;
  email: string;
  role: string;
  projects: IProject[];
  createdAt: Date;

  refreshToken?: string;
  accessToken?: string;
}

export interface InitOfficerState {
  isLoading: boolean;
  officers: IOfficer[];
  error: { message?: string } | null;
}

export interface ISignupPayload {
  username: string;
  email: string;
  password: string;
  confirm_password: string;
  projects: string[];
}

export interface IUpdateUserPayload {
  id: number;
  payload: {
    username?: string;
    email?: string;
    password?: string;
    projects?: string[];
    role?: string;
  };
}

export const ROLES = {
  ADMIN: 'admin', // can be the number
  USER: 'user',
};
