import { useEffect, useState } from 'react';
import {
  changeFullName,
  changePassword,
  selectUser,
} from '../../app/slices/userSlice';
import { useAppDispatch, useAppSelector } from '../../app/store';
import Header from '../../components/Header';
import Prelaoder from '../Preloader';
import { toast } from 'react-toastify';
import './index.css';
import { IOfficer } from '../../types/contract';
import tokenService from '../../services/token.service';

export const Account = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectUser);

  // get user from tokenService
  const user = tokenService.getUser() as IOfficer;

  const [fullName, setFullName] = useState(user.username);
  const [old, setOld] = useState('');
  const [new1, setNew1] = useState('');
  const [new2, setNew2] = useState('');
  const [invalidText, setInvalidText] = useState({
    old: '',
    new1: '',
    new2: '',
  });
  const [submitType, setSubmitType] = useState('');
  useEffect(() => {
    if (old) {
      if (!new1) {
        setInvalidText({
          ...invalidText,
          new1: 'Password is required',
          new2: '',
        });
        return;
      } else {
        new1 === new2
          ? setInvalidText({ ...invalidText, new2: '' })
          : setInvalidText({
              ...invalidText,
              new1: '',
              new2: 'Password is not matched',
            });
      }
    } else {
      setInvalidText({
        old: '',
        new1: '',
        new2: '',
      });
    }
  }, [old, new1, new2]);

  const isValidate = () => {
    if (!new1) {
      return false;
    }
    if (new1 !== new2) {
      return false;
    }
    return true;
  };

  const updateFullName = () => {
    if (isLoading) return;
    setSubmitType('fullName');
    dispatch(changeFullName(fullName as string)).then((data: any) => {
      setSubmitType('');
      if (data?.error) {
        toast.error(data?.error.message);
      } else {
        toast.success('Full name was changed!');
      }
    });
  };

  const updatePassword = () => {
    if (isLoading) return;
    if (!isValidate()) {
      return;
    }
    setSubmitType('password');
    dispatch(changePassword({ old, new: new1 })).then((data: any) => {
      setSubmitType('');
      if (data?.error) {
        toast.error(data?.error.message);
        setInvalidText({
          ...invalidText,
          old: data?.error.message,
        });
      } else {
        toast.success('Password was changed!');
        setOld('');
        setNew1('');
        setNew2('');
        setInvalidText({
          old: '',
          new1: '',
          new2: '',
        });
      }
    });
  };

  return (
    <>
      <Header breadcumbs={[]} />
      <div className="account-container">
        <div className="change-fullName section">
          <div className="title">
            <h4>Change Full name</h4>
            <hr />
          </div>
          <div className="form-wrapper">
            <div className="form-row">
              <label htmlFor="fullName">Full name</label>
              <input
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                id="fullName"
                placeholder="Full name"
              />
            </div>
            <div className="form-row right">
              <button
                disabled={!fullName || user?.username === fullName}
                className="submit-button"
                onClick={updateFullName}
              >
                Change
                <Prelaoder
                  loader={submitType === 'fullName' && isLoading}
                />
              </button>
            </div>
          </div>
        </div>
        <div className="change-password section">
          <div className="title">
            <h4>Change Password</h4>
            <hr />
          </div>
          <div className="form-wrapper">
            <div className="form-row">
              <label htmlFor="old">Old password</label>
              <input
                id="old"
                value={old}
                onChange={(e) => setOld(e.target.value)}
                placeholder="Old password"
              />
              {!!invalidText.old && (
                <span className="error">{invalidText.old}</span>
              )}
            </div>
            <div className="form-row">
              <label htmlFor="new1">New password</label>
              <input
                id="new1"
                value={new1}
                onChange={(e) => setNew1(e.target.value)}
                placeholder="New password"
              />
              {!!invalidText.new1 && (
                <span className="error">{invalidText.new1}</span>
              )}
            </div>
            <div className="form-row">
              <label htmlFor="new2">Confirm new password</label>
              <input
                id="new2"
                value={new2}
                onChange={(e) => setNew2(e.target.value)}
                placeholder="Confirm new password"
              />
              {!!invalidText.new2 && (
                <span className="error">{invalidText.new2}</span>
              )}
            </div>
            <div className="form-row right">
              <button
                disabled={
                  !old || !!invalidText.new1 || !!invalidText.new2
                }
                className="submit-button"
                onClick={updatePassword}
              >
                Change
                <Prelaoder
                  loader={submitType === 'password' && isLoading}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
