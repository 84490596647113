import { useMemo, useState, FC, Fragment } from 'react';
import { Cell, useTable } from 'react-table';
import Skeleton from 'react-loading-skeleton';
import { format } from 'date-fns';
import './index.css';
import {
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TRANSACTION_COLUMN } from '../../constants/columns_data';
import { ITransaction } from '../../types/transaction';
import { TablePaginated } from '../TablePaginated';
interface ITable {
  isLoading?: boolean;
  tableDatas: any[];
  tableColumns: any[];
  isNested?: boolean;
  tab?: { activeTab: string; page: string };
  expand?: boolean;
}

export interface detailTransaction {
  date: string;
  description: string;
  withdrawls: string;
  deposits: string;
  balance: string;
}

const Table: FC<ITable> = ({
  isLoading,
  tableDatas,
  tableColumns,
  isNested = false,
  tab = { activeTab: '', page: '' },
  expand = true,
}) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState<Cell[]>([]);
  const [dataDetails, setDataDetails] = useState<ITransaction[]>([]);

  const columns = useMemo(
    () =>
      isLoading
        ? tableColumns.map((column, i) => ({
            ...column,
            Cell: (
              <Skeleton
                key={i}
                width={80}
                height={18}
              />
            ),
          }))
        : tableColumns,
    [tableColumns, isLoading],
  );
  const data = useMemo(
    () => (isLoading ? Array(6).fill({}) : tableDatas),
    [tableDatas, isLoading],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows: setRows,
    prepareRow,
  } = useTable({ columns, data });

  const handleExpandTable = (id: number) => {
    if (isLoading) return;
    if (tab.page === 'insight') {
      navigate(`/insight-details/${id}`);
    } else {
      setDataDetails(
        tableDatas.find((account) => account?.id === id)
          ?.__transactions__,
      );
    }
  };

  const handleExpandNestedTable = (cells: Cell[]) => {
    if (isLoading) return;
    if (isNested) {
      if (selected.length > 0) {
        if (selected[0].row.index === cells[0].row.index)
          setSelected([]);
        else setSelected(cells);
      } else {
        setSelected(cells);
      }
    }
  };

  const setClassString = (cell: Cell): string => {
    if (
      cell.column.id === 'OwnerName' ||
      cell.column.id === 'accountName'
    ) {
      return 'clickable';
    }

    if (
      cell.column.id === 'accountNumber' ||
      cell.column.id === 'accountNumber' ||
      cell.column.id === 'accountBalance' ||
      cell.column.id === 'AvailableBalance' ||
      cell.column.id === 'routingNumber'
    ) {
      return 'num-row';
    }
    return '';
  };

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={index}
          >
            {expand && <th></th>}
            {headerGroup.headers.map((column, index1) => (
              <th
                {...column.getHeaderProps()}
                key={index1}
              >
                <div>
                  {column.render('Header')}
                  {/* {renderIcon(column.id)} */}
                </div>
              </th>
            ))}
            <th>Action</th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {setRows.map((row, i) => {
          const leadId = row.original.id;
          prepareRow(row);
          return (
            <Fragment key={i}>
              <tr
                {...row.getRowProps()}
                onClick={() => handleExpandNestedTable(row.cells)}
              >
                {expand && (
                  <td
                    className="table__chevron"
                    onClick={() => handleExpandTable(leadId)}
                  >
                    <FontAwesomeIcon
                      icon={
                        selected &&
                        row.index === selected[0]?.row?.index
                          ? faChevronUp
                          : faChevronDown
                      }
                    />
                  </td>
                )}
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      onClick={() => handleExpandTable(leadId)}
                      className={setClassString(cell)}
                      key={i}
                    >
                      <div
                        className={`${
                          tab.page === 'insight' && 'table__data'
                        } ${cell.value}`}
                      >
                        {cell.render('Cell')}
                      </div>
                    </td>
                  );
                })}
                <td>
                  <button></button>
                </td>
              </tr>
              {selected.length > 0
                ? row.index === selected[0]?.row.index && (
                    <tr className="table__view">
                      <td colSpan={tableColumns.length + 2}>
                        <TablePaginated
                          columns={TRANSACTION_COLUMN}
                          data={dataDetails.map((row) => ({
                            ...row,
                            transactionDate: format(
                              new Date(row.transactionDate),
                              'yyyy-MM-dd',
                            ),
                            transactionAmount:
                              row.transactionAmount < 0
                                ? `-$${Math.abs(row.transactionAmount)}`
                                : `$${row.transactionAmount}`,
                          }))}
                        />
                      </td>
                    </tr>
                  )
                : null}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
