import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import leadDetailReducer from './slices/leadDetailSlice';
import leadsReducer from './slices/leadsSlice';
import userReducer from './slices/userSlice';
import officersReducer from './slices/officersSlice';
import projectsReducer from './slices/projectsSlice';

const store = configureStore({
  reducer: {
    leads: leadsReducer,
    leadDetail: leadDetailReducer,
    user: userReducer,
    officers: officersReducer,
    projects: projectsReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelector;
