import { useEffect } from 'react';
import Header from '../../components/Header';
import { useLayout } from '../../contexts/LayoutContext';

const ErrorPage = () => {
  const { setExpand } = useLayout();

  useEffect(() => {
    setExpand(false);
  }, []);

  return (
    <>
      <Header breadcumbs={[]} />
      <div>404 Not Found</div>
    </>
  );
};

export default ErrorPage;
