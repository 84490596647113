import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  InitOfficerState,
  IOfficer,
  ISignupPayload,
  IUpdateUserPayload,
} from '../../types/contract';
import axiosIns from '../../utils/axiosInstance';
import { RootState } from '../store';

const initialState: InitOfficerState = {
  isLoading: false,
  officers: [],
  error: null,
};

export const fetchOfficers = createAsyncThunk<IOfficer[], undefined>(
  '/users',
  async () => {
    return await axiosIns.get('/users');
  },
);

export const createOfficer = createAsyncThunk<IOfficer, ISignupPayload>(
  '/users/signup',
  async (signupPayload) => {
    return await axiosIns.post(
      '/users/register-by-admin',
      signupPayload,
    );
  },
);

export const updateOfficer = createAsyncThunk<
  IOfficer,
  IUpdateUserPayload
>('/users/update', async ({ id, payload }) => {
  return await axiosIns.put(`/users/${id}`, payload);
});

export const removeOfficer = createAsyncThunk<IOfficer, number>(
  '/users/delete',
  async (id) => {
    return await axiosIns.delete(`/users/${id}`);
  },
);

const officersSlice = createSlice({
  name: 'officers',
  initialState,
  reducers: {
    addOfficer(
      state: InitOfficerState,
      action: PayloadAction<IOfficer>,
    ) {
      state.officers.push(action.payload);
    },
  },
  extraReducers(builder: ActionReducerMapBuilder<InitOfficerState>) {
    builder
      .addCase(fetchOfficers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOfficers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.officers = action.payload;
        state.error = null;
      })
      .addCase(fetchOfficers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error as { message: string };
      })
      .addCase(createOfficer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        createOfficer.fulfilled,
        (state, {payload: createdOfficer}) => {
          state.isLoading = false;
          state.officers.push(createdOfficer);
          state.error = null;
        },
      )
      .addCase(createOfficer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error as { message: string };
      })
      .addCase(updateOfficer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateOfficer.fulfilled,
        (state, {payload: updatedOfficer}) => {
          state.isLoading = false;
          state.officers = state.officers.map((o) =>
            o.id === updatedOfficer.id ? updatedOfficer : o,
          );
          state.error = null;
        },
      )
      .addCase(updateOfficer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error as { message: string };
      })
      .addCase(removeOfficer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        removeOfficer.fulfilled,
        (state, {payload: removedOfficer}) => {
          state.isLoading = false;
          state.officers = state.officers.filter(
            (o) => o.id != removedOfficer.id,
          );
          state.error = null;
        },
      )
      .addCase(removeOfficer.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error as { message: string };
      });
  },
});

export const selectOfficers = (state: RootState) => state.officers;

export default officersSlice.reducer;
