import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const signupSchema = yup.object({
  username: yup.string().required('Username is required.'),
  password: yup.string().required('Password is required.'),
  confirm_password: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  email: yup
    .string()
    .email('Not valid email address')
    .required('Email is required.'),
  projects: yup.array().min(1).required(),
});

const updateUserSchema = yup.object({
  username: yup.string().required('Username is required.'),
  email: yup
    .string()
    .email('Not valid email address')
    .required('Email is required.'),
  projects: yup.array().min(1).required(),
});

export const signupResolver = yupResolver(signupSchema);
export const updateResolver = yupResolver(updateUserSchema);
