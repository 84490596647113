import { ChangeEvent, MouseEvent, FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateLogin } from '../../utils/validationForm';
import Prelaoder from '../Preloader';
import { loginUser, selectUser } from '../../app/slices/userSlice';
import './index.css';
import { useAppDispatch, useAppSelector } from '../../app/store';

export interface IReduxPromise {
  error: { message: string } | undefined;
  meta: unknown;
  payload: { role: string };
  type: string;
}

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(selectUser);

  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState<{
    email: string;
    password: string;
  } | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    if (isLoading) return;

    const { valid, errors } = validateLogin(values);
    setErrors(null);
    if (!valid) {
      setErrors(errors);
      return;
    }
    dispatch(loginUser(values)).then((data: IReduxPromise | any) => {
      if (!data?.error) {
        if (data?.payload?.role === 'admin') navigate('/admin/users');
        else navigate('/insight');
      } else {
        console.log('login error', data?.error.message);
        setErrors({
          email: 'Invalid email',
          password: 'Invalid password',
        });
      }
    });
  };

  return (
    <div className="login">
      <div>
        <div className="logo">
          <span>
            <FontAwesomeIcon icon={faDollarSign} />
          </span>
          <h2>Sophtron Fintech</h2>
        </div>
        <div className="login__container">
          <h1>Welcome Back!</h1>
          <div>
            <div>
              <label htmlFor="login-email">Email Address</label>
              <input
                type="email"
                id="login-email"
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className={`${errors?.email && 'error-input'}`}
              />
              {errors?.email && <span className="error">{errors?.email}</span>}
            </div>
            <div>
              <label htmlFor="login-pasword">Password</label>
              <input
                type="password"
                id="login-pasword"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder="Enter your password"
                className={`${errors?.password && 'error-input'}`}
              />
              {errors?.password && <span className="error">{errors?.password}</span>}
            </div>
            <button
              className="submit-button"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Login <Prelaoder loader={isLoading} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
