import { FC, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import './index.css';

interface SlideSlideProps {
  open: boolean;
  onClose: () => void;
  children: ReactElement;
}

export const SideSlide: FC<SlideSlideProps> = (props) => {
  const { open, onClose, children } = props;

  return (
    <>
      <div className={open ? 'side__slide show' : 'side__slide hide'}>
        <div className="inner__container">
          <button onClick={() => onClose()}>
            <FontAwesomeIcon icon={faClose} />
          </button>
          {children}
        </div>
      </div>
    </>
  );
};
