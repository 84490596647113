import { FC } from 'react';
import { ACCOUNT_COLUMNS } from '../../../constants/columns_data';
import { IAccount } from '../../../types/account';
import { ILead } from '../../../types/lead';
import Table from '../../Table';
import InsightDetailsData from '../InsightDetailsData';
// import InsightDetailsInfo from '../InsightDetailsInfo';
import './index.css';

interface IOverviewTab {
  isLoading: boolean;
  lead: ILead;
  accounts: IAccount[];
}

const OverviewTab: FC<IOverviewTab> = ({
  lead,
  accounts,
  isLoading,
}) => {
  return (
    <div className="insightDetails__content">
      <InsightDetailsData
        isLoading={isLoading}
        name={lead?.OwnerName || ''}
        email={
          (lead?.email && lead?.email.length && lead?.email[0]) ||
          'example@gmail.com'
        }
        address={lead?.Address || ''}
      />
      <div className="insightDetails__contentRight">
        {/* <InsightDetailsInfo isLoading={isLoading} name={'Jhon Doe'} /> */}
        {accounts && (
          <div className="insightDetails__table">
            <Table
              isLoading={isLoading}
              isNested={true}
              tableDatas={accounts.map((act) => ({
                ...act,
                accountBalance: `$${act?.accountBalance}`,
              }))}
              tableColumns={ACCOUNT_COLUMNS}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewTab;
