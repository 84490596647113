import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AutoLogout } from '../components/AutoLogout';
import tokenService from '../services/token.service';

export type ProtectedRouteProps = {
  allowedRoles: string[];
  authenticationPath?: string;
};
export default function ProtectedRoute({ allowedRoles, authenticationPath = '/login' }: ProtectedRouteProps) {
  const location = useLocation();
  const user = tokenService.getUser();

  return !Object.entries(user).length ? (
    <Navigate
      to={{ pathname: authenticationPath }}
      state={{ from: location }}
      replace
    />
  ) : allowedRoles.includes(user.role) ? (
    <AutoLogout>{<Outlet />}</AutoLogout>
  ) : (
    <Navigate
      to="/unauthorized"
      state={{ from: location }}
      replace
    />
  );
}
