import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { useLayout } from '../../contexts/LayoutContext';

interface AdminDashboardProps {}

export const AdminDashboard: FC<AdminDashboardProps> = () => {
  const navigate = useNavigate();
  const { setIsAdmin } = useLayout();

  useEffect(() => {
    setIsAdmin(true);
  }, [navigate, setIsAdmin]);

  return (
    <>
      <Header breadcumbs={[]} />
      <div>admin dashboard</div>
    </>
  );
};
