import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import Skeleton from 'react-loading-skeleton';

interface IInsightDetailsInfo {
  isLoading?: boolean;
  name?: string;
}

const InsightDetailsInfo: React.FC<IInsightDetailsInfo> = ({
  name,
  isLoading,
}) => {
  return (
    <div className="insightDetailsInfo">
      <div>
        <h3>
          Employer Info
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17_136)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_136">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </h3>
        <div className="insightDetailsInfo__incomeDetail employer">
          <div className="insightDetailsInfo__income employer-info">
            {isLoading && (
              <strong>
                <Skeleton width={120} />
              </strong>
            )}
            {!isLoading && <strong>{name}</strong>}
            <h4>
              Employer Name
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_17_136)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_136">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </h4>
          </div>
          <div className="insightDetailsInfo__income">
            <strong>$1,123.23</strong>
            <h4>Employer Income</h4>
            <small>(Average Monthly)</small>
          </div>
          <div className="insightDetailsInfo__income">
            <FontAwesomeIcon
              icon={faArrowTrendUp}
              size="2x"
              color="green"
            />
            <h4>
              Employer Inc. Trend
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_17_136)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_136">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </h4>
          </div>
        </div>
      </div>
      <div>
        <h3>
          Non-Employer Info
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_17_136)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_17_136">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </h3>
        <div className="insightDetailsInfo__incomeDetail">
          <div className="insightDetailsInfo__income">
            <strong>$1,123.23</strong>
            <h4>Employer Income</h4>
            <small>(Average Monthly)</small>
          </div>
          <div className="insightDetailsInfo__income">
            <strong>$1,123.23</strong>
            <h4>Employer Income</h4>
            <small>(Average Monthly)</small>
          </div>
          <div className="insightDetailsInfo__income">
            <FontAwesomeIcon
              icon={faArrowTrendUp}
              size="2x"
              color="green"
            />
            <h4>
              Employer Inc. Trend
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_17_136)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.75 11.25H8.25V6.75H6.75V11.25ZM7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 3.375 11.625 0 7.5 0ZM7.5 13.5C4.2 13.5 1.5 10.8 1.5 7.5C1.5 4.2 4.2 1.5 7.5 1.5C10.8 1.5 13.5 4.2 13.5 7.5C13.5 10.8 10.8 13.5 7.5 13.5ZM6.75 5.25H8.25V3.75H6.75V5.25Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_17_136">
                    <rect width="15" height="15" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightDetailsInfo;
