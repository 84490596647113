export const LEAD_COLUMNS = [
  {
    Header: 'Owner name',
    accessor: 'OwnerName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Address',
    accessor: 'Address',
  },
  {
    Header: 'Birth',
    accessor: 'birthday',
  },
];

export const REQUEST_COLUMNS = [
  {
    Header: 'Request',
    accessor: 'requestName',
  },
  {
    Header: 'Request Status',
    accessor: 'requestStatus',
  },
  {
    Header: 'Request Id',
    accessor: 'requestId',
  },
  {
    Header: 'Activity',
    accessor: 'requestActivity',
  },
  {
    Header: 'Finansial Institution',
    accessor: 'financialInstitution',
  },
  {
    Header: 'Time/Date',
    accessor: 'requestDateTime',
  },
];

export const ACCOUNT_COLUMNS = [
  {
    Header: 'Account Name',
    accessor: 'accountName',
  },
  {
    Header: 'Account Number',
    accessor: 'accountNumber',
  },
  {
    Header: 'Account type',
    accessor: 'accountType',
  },
  {
    Header: 'Account Balance',
    accessor: 'accountBalance',
  },
  {
    Header: 'AvailableBalance',
    accessor: 'AvailableBalance',
  },
  // {
  //   Header: "LastUpdated",
  //   accessor: "LastUpdated",
  // },
  // {
  //   Header: "DueDate",
  //   accessor: "DueDate",
  // },
  {
    Header: 'Routing Number',
    accessor: 'routingNumber',
  },
  {
    Header: 'Institution Name',
    accessor: 'InstitutionName',
  },
  // {
  //   Header: "CashBalance",
  //   accessor: "CashBalance",
  // },
];

export const COLUMNS_CUSTOMER_DETAILS = [
  {
    Header: 'Account Name',
    accessor: 'account_name',
  },
  {
    Header: 'Account Number',
    accessor: 'account_number',
  },
  {
    Header: 'Routing Number',
    accessor: 'routingNumber',
  },
  {
    Header: 'Account type',
    accessor: 'account_type',
  },
  {
    Header: 'Account Balance',
    accessor: 'account_balance',
  },
  {
    Header: 'Statements',
    accessor: 'statments',
  },
];

export const TRANSACTION_COLUMN = [
  {
    Header: 'Transaction Date',
    accessor: 'transactionDate',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Transaction Amount',
    accessor: 'transactionAmount',
  },
  {
    Header: 'Transaction Type',
    accessor: 'transactionType',
  },
  {
    Header: 'Status',
    accessor: 'Status',
  },
  {
    Header: 'Balance',
    accessor: 'Balance',
  },
  {
    Header: 'Category',
    accessor: 'Category',
  },
  // {
  //   Header: "Memo",
  //   accessor: "Memo",
  // },
  // {
  //   Header: "Merchant",
  //   accessor: "Merchant",
  // },
];

export const COLUMNS_INCOME_GOVERNMENT = [
  {
    Header: 'Income Source',
    accessor: 'income_source',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
];

export const COLUMNS_LOAN = [
  {
    Header: 'Loan Source',
    accessor: 'loan_source',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
];

export const USER_COLUMNS = [
  {
    Header: 'Username',
    accessor: 'userName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Projects',
    accessor: 'projects',
  },
  {
    Header: 'Sign Date',
    accessor: 'createdAt',
  },
  {
    id: 'edit',
    accessor: '[row identifier to be passed to button]',
  },
];
