import { FallbackProps } from 'react-error-boundary';
import './index.css';

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  return (
    <div className='error-boundary' role="alert">
      <h4>React Error Boundary Issues</h4>
      <pre>{error?.message as string}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};
