const emailPatten =
  /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
// const passwordPatten =
//   /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

export const validateLogin = (props: {
  email: string;
  password: string;
}) => {
  const errors: { email: string; password: string } | null = {
    email: '',
    password: '',
  };
  if (!props.email?.trim()) {
    errors.email = 'Email should not be empty';
  } else if (!props.email.match(emailPatten)) {
    errors.email = 'Email must be valid email addressed';
  } else if (!props.password?.trim()) {
    errors.password = 'Password should not be empty';
  }

  return {
    errors,
    valid: Object.values(errors).every(
      (element) => element.length === 0,
    ),
  };
};
