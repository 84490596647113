import axios from 'axios';
import tokenService from '../services/token.service';

const apiURL = process.env.REACT_APP_BASE_URL;

const axiosIns = axios.create({
  baseURL: apiURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosIns.interceptors.request.use((config) => {
  const token = tokenService.getLocalAccessToken();
  config['headers'] = {
    ...(config.headers ?? {}),
    platform: 'web',
    Authorization: `Bearer ${token}`,
  };
  return config;
});

axiosIns.interceptors.response.use(
  (response) => {
    // console.log('[===== Ended API Call =====]', response);
    const accessToken = response.headers?.authorization;
    if (accessToken) {
      tokenService.setUser(response.data, accessToken);
    }
    return response.data;
  },
  async (error) => {
    console.log('error==============', error);
    const res = error.response;
    const originalConfig = error.config;

    if (originalConfig.url !== '/auth/login' && res) {
      if (res.status === 401 && !originalConfig._retry) {
        // access token was expired,
        console.log('access token was expired\n');

        originalConfig._retry = true;

        try {
          const rs = await axiosIns.post('/auth/refresh', {
            // should get refresh token from tokenService
            refreshToken: tokenService.getLocalRefreshToken(),
          });

          const newAccessToken = rs.headers?.authorization;
          console.log('got new access token\n');
          // update old access token into new
          tokenService.updateLocalAccessToken(newAccessToken as string);

          // call prev request again with new access token
          originalConfig.headers = {
            ...originalConfig.headers,
            'Content-Type': 'application/json',
          };
          return axiosIns(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    if (originalConfig.url === '/auth/refresh' && res && res.status === 400) {
      tokenService.removeUser();
      window.location.href = '/login';
    }
    return Promise.reject(res.data.details || "Unknown issues from server");
  },
);

export default axiosIns;
