import React, { useEffect, useRef, useState } from 'react';
import {
  faArrowsRotate,
  faChevronDown,
  faPen,
  faPrint,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';

interface IInsightDetailsHeader {
  tabs: string[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const InsightDetailsHeader: React.FC<IInsightDetailsHeader> = ({
  tabs,
  activeTab,
  setActiveTab,
}) => {
  const [openDropdownAction, setOpenDropdownAction] = useState(false);

  const showDropdownRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const handleCloseSelect = (e: any) => {
      if (
        showDropdownRef?.current &&
        showDropdownRef.current.contains(e.target)
      )
        return;
      setOpenDropdownAction(false);
    };
    window.addEventListener('mouseup', handleCloseSelect);
    return () =>
      window.removeEventListener('mouseup', handleCloseSelect);
  }, [openDropdownAction]);

  return (
    <div className="insightDetails__header">
      <ul className="insightDetails__headerOptions">
        {tabs.map((tab, i) => (
          <li
            className={`${tab === activeTab && 'active'}`}
            key={tab + i}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </li>
        ))}
      </ul>
      {/* <div className="insightDetails__headerRight">
        <button className="insightDetails__headerBtnPrint">
          <FontAwesomeIcon icon={faPrint} />
        </button>
        <button className="insightDetails__headerBtnRefresh">
          Refresh
          <FontAwesomeIcon icon={faArrowsRotate} />
        </button>
        <button
          className="insightDetails__headerBtnAction"
          onClick={() => setOpenDropdownAction(!openDropdownAction)}
        >
          <span>
            Take Action
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
          {openDropdownAction && (
            <ul ref={showDropdownRef}>
              <li className="insightDetails__headerBtnActionDelete">
                Delete
                <FontAwesomeIcon icon={faTrash} />
              </li>
              <li className="insightDetails__headerBtnActionEdit">
                Edit
                <FontAwesomeIcon icon={faPen} />
              </li>
            </ul>
          )}
        </button>
      </div> */}
    </div>
  );
};

export default InsightDetailsHeader;
