import {
  createSlice,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { IOfficer } from '../../types/contract';
import axiosIns from '../../utils/axiosInstance';
import { RootState } from '../store';

interface IUserState {
  isLoading: boolean;
  user: IOfficer | null;
  error: { message: string } | null;
}

export interface ILoginPayload {
  email: string;
  password: string;
}

const initialState: IUserState = {
  isLoading: false,
  user: null,
  error: null,
};

export const loginUser = createAsyncThunk<IOfficer, ILoginPayload>(
  'auth/login',
  async (payload) => {
    return await axiosIns.post('auth/login', payload);
  },
);

export const changeFullName = createAsyncThunk<IOfficer, string>(
  'me/change-fullname',
  async (fullName) => {
    return await axiosIns.put('users/me/change-fullname', { fullName });
  },
);

export const changePassword = createAsyncThunk<
  IOfficer,
  { old: string; new: string }
>('me/change-password', async (request) => {
  return await axiosIns.put('users/me/change-password', request);
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUser(state) {
      state.user = null;
    },
  },
  extraReducers(builder: ActionReducerMapBuilder<IUserState>) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload: user }) => {
        state.isLoading = false;
        state.user = user;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error as { message: string };
      })
      .addCase(changeFullName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeFullName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(changeFullName.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error as { message: string };
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error as { message: string };
      });
  },
});

export const selectUser = (state: RootState) => state.user;
export const { removeUser } = userSlice.actions;

export default userSlice.reducer;
