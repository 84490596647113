import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './app/store';
import { fetchProjects } from './app/slices/projectsSlice';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/ErrorFallback';

store.dispatch(fetchProjects());

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.error(error);
  console.info(info);
  // Do something with the error
  // E.g. log to an error logging client here
  // will do log handling later
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={myErrorHandler}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/*"
              element={<App />}
            />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
      <ToastContainer />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
