import { FC } from 'react';
import ReactLoading from 'react-loading';
import './index.css';
interface IPreLoader {
  loader: boolean;
}
const Prelaoder: FC<IPreLoader> = ({ loader }) => {
  if (!loader) return null;

  return (
    <div className="loader-wrapper">
      <ReactLoading
        type={'bars'}
        color={'#ffffff'}
        width={40}
        height={40}
      />
    </div>
  );
};

export default Prelaoder;
