import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton from 'react-loading-skeleton';
import {
  faChevronUp,
  faChevronDown,
  faBars,
  faChevronRight,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import './index.css';
import { useAppDispatch } from '../../app/store';
import { removeUser } from '../../app/slices/userSlice';
import { useLayout } from '../../contexts/LayoutContext';
import tokenService from '../../services/token.service';

interface IHeader {
  isLoading?: boolean;
  breadcumbs: { label: string }[];
}

const Header: React.FC<IHeader> = ({ isLoading, breadcumbs }) => {
  const user = tokenService.getUser();
  const dispatch = useAppDispatch();
  const { expand, setExpand } = useLayout();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [activeNavbar, setActiveNavbar] = useState<string>();
  const [tab, setTab] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const showDropdownRef = useRef<HTMLUListElement>(null);

  const handleSidebar = (page: string) => {
    // logout handler
    if (page === 'login') {
      tokenService.removeUser();
      dispatch(removeUser());
    }
    navigate(`/${page}`);
    setActiveNavbar(page);
  };

  useEffect(() => {
    const pathname = location.pathname.substring(1);

    setTab(pathname === '' ? 'Insight' : pathname.split('-').length ? pathname.split('-')[0] : pathname);
    setActiveNavbar(pathname);
  }, [location]);

  useEffect(() => {
    const handleCloseSelect = (e: any) => {
      if (showDropdownRef?.current && showDropdownRef.current.contains(e.target)) return;
      setShowDropdown(false);
    };
    window.addEventListener('mouseup', handleCloseSelect);
    return () => window.removeEventListener('mouseup', handleCloseSelect);
  }, [showDropdown]);

  return (
    <header className={`header container ${location.pathname.includes('login') && 'login-page'}`}>
      <div className="header__left">
        <div
          className="header__expand"
          onClick={() => setExpand(!expand)}
        >
          <FontAwesomeIcon icon={faBars} />
        </div>
        {/* <div className="logo">
          <span>
          <FontAwesomeIcon icon={faDollarSign} />
          </span>
          <h2>
            Sophtron <br /> Fintech
          </h2>
        </div> */}
        <div className="header__pageDetails">
          <h1>{tab}</h1>
          {breadcumbs.map((cumb, i) => (
            <Fragment key={i}>
              <FontAwesomeIcon icon={faChevronRight} />
              {isLoading && (
                <h3>
                  <Skeleton width={140} />
                </h3>
              )}
              {!isLoading && <h3>{breadcumbs[0].label}</h3>}
            </Fragment>
          ))}
        </div>
      </div>
      <ul className="header__items">
        {/* <li onClick={() => navigate('status')}>Status</li>
        <li onClick={() => navigate('help')}>Help</li> */}
        <li>{!!user && user.username}</li>
        <li
          className="header__itemDropdown"
          onClick={() => setShowDropdown((showDropdown) => !showDropdown)}
        >
          <FontAwesomeIcon
            icon={faUser}
            className="header__userIcon"
          />
          <FontAwesomeIcon icon={showDropdown ? faChevronUp : faChevronDown} />
          {showDropdown && (
            <ul ref={showDropdownRef}>
              <li
                className={`${activeNavbar === 'account' && 'active'}`}
                onClick={() => handleSidebar('account')}
              >
                <FontAwesomeIcon icon={faUser} />
                Account
              </li>
              {/* <li
                className={`${activeNavbar === 'setting' && 'active'}`}
                onClick={() => handleSidebar('setting')}
              >
                <svg version="1.1" viewBox="0 0 78.197 76.232">
                  <path d="M78.197,37.708l-3.726-16.331l-9.873-1.097l-2.511-3.15l1.131-9.865L48.122,0l-7.008,7.028h-4.031  L30.073,0L14.979,7.266l1.131,9.865l-2.511,3.15l-9.873,1.097L0,37.708l8.413,5.271l0.898,3.928l-5.299,8.397l10.45,13.098  l9.365-3.293l3.632,1.749l3.262,9.375h16.754l3.262-9.375l3.632-1.749l9.365,3.293l10.45-13.098l-5.299-8.397l0.897-3.928  L78.197,37.708z M66.833,54.897l-5.13,6.43l-7.693-2.705l-8.12,3.911l-2.68,7.699h-8.223l-2.68-7.699l-8.12-3.911l-7.693,2.705  l-5.13-6.43L15.717,48l-2.01-8.784l-6.908-4.329l1.829-8.017l8.109-0.901l5.617-7.046l-0.93-8.101l7.41-3.567l5.758,5.773h9.012  l5.757-5.773l7.411,3.566l-0.93,8.101l5.617,7.046l8.109,0.901l1.829,8.017l-6.91,4.329L62.481,48L66.833,54.897z M39.099,23.587  c-8.286,0-15,6.713-15,15s6.714,15,15,15s15-6.713,15-15S47.385,23.587,39.099,23.587z M39.099,49.087c-5.79,0-10.5-4.71-10.5-10.5  s4.71-10.5,10.5-10.5s10.5,4.71,10.5,10.5S44.889,49.087,39.099,49.087z" />
                </svg>
                Setting
              </li> */}
              <li onClick={() => handleSidebar('login')}>
                <FontAwesomeIcon icon={faRightFromBracket} />
                Logout
              </li>
            </ul>
          )}
        </li>
      </ul>
    </header>
  );
};

export default Header;
